*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(63, 131, 248, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(63, 131, 248, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  line-height: 1.5;
}

:host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Consolas, Menlo, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

input:where([type="button"]) {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

input:where([type="reset"]) {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

[multiple], textarea, select {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"] {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

input:where(:not([type])) {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="email"] {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="url"] {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="password"] {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="number"] {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="date"] {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="datetime-local"] {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="month"] {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="search"] {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="tel"] {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="time"] {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="week"] {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid rgba(0, 0, 0, 0);
}

[type="text"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid rgba(0, 0, 0, 0);
}

input:where(:not([type])):focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid rgba(0, 0, 0, 0);
}

[type="email"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid rgba(0, 0, 0, 0);
}

[type="url"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid rgba(0, 0, 0, 0);
}

[type="password"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid rgba(0, 0, 0, 0);
}

[type="number"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid rgba(0, 0, 0, 0);
}

[type="date"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid rgba(0, 0, 0, 0);
}

[type="datetime-local"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid rgba(0, 0, 0, 0);
}

[type="month"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid rgba(0, 0, 0, 0);
}

[type="search"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid rgba(0, 0, 0, 0);
}

[type="tel"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid rgba(0, 0, 0, 0);
}

[type="time"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid rgba(0, 0, 0, 0);
}

[type="week"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid rgba(0, 0, 0, 0);
}

input::placeholder {
  color: #6b7280;
  opacity: 1;
}

textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
  text-align: inherit;
}

::-webkit-datetime-edit {
  display: inline-flex;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #1c64f2;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #1c64f2;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid rgba(0, 0, 0, 0);
}

[type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid rgba(0, 0, 0, 0);
}

[type="checkbox"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: rgba(0, 0, 0, 0);
}

[type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: rgba(0, 0, 0, 0);
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="checkbox"]:checked {
    -webkit-appearance: auto;
    appearance: auto;
  }
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="radio"]:checked {
    -webkit-appearance: auto;
    appearance: auto;
  }
}

[type="checkbox"]:checked:hover {
  background-color: currentColor;
  border-color: rgba(0, 0, 0, 0);
}

[type="checkbox"]:checked:focus {
  background-color: currentColor;
  border-color: rgba(0, 0, 0, 0);
}

[type="radio"]:checked:hover {
  background-color: currentColor;
  border-color: rgba(0, 0, 0, 0);
}

[type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: rgba(0, 0, 0, 0);
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: rgba(0, 0, 0, 0);
}

@media (forced-colors: active) {
  [type="checkbox"]:indeterminate {
    -webkit-appearance: auto;
    appearance: auto;
  }
}

[type="checkbox"]:indeterminate:hover {
  background-color: currentColor;
  border-color: rgba(0, 0, 0, 0);
}

[type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: rgba(0, 0, 0, 0);
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid buttontext;
  outline: 1px auto -webkit-focus-ring-color;
}

.tooltip-arrow, .tooltip-arrow:before {
  background: inherit;
  width: 8px;
  height: 8px;
  position: absolute;
}

.tooltip-arrow {
  visibility: hidden;
}

.tooltip-arrow:before {
  content: "";
  visibility: visible;
  transform: rotate(45deg);
}

[data-tooltip-style^="light"] + .tooltip > .tooltip-arrow:before {
  border-style: solid;
  border-color: var(--color-gray-200);
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="top"] > .tooltip-arrow:before {
  border-bottom-width: 1px;
  border-right-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="right"] > .tooltip-arrow:before {
  border-bottom-width: 1px;
  border-left-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="bottom"] > .tooltip-arrow:before {
  border-top-width: 1px;
  border-left-width: 1px;
}

[data-tooltip-style^="light"] + .tooltip[data-popper-placement^="left"] > .tooltip-arrow:before {
  border-top-width: 1px;
  border-right-width: 1px;
}

.tooltip[data-popper-placement^="top"] > .tooltip-arrow {
  bottom: -4px;
}

.tooltip[data-popper-placement^="bottom"] > .tooltip-arrow {
  top: -4px;
}

.tooltip[data-popper-placement^="left"] > .tooltip-arrow {
  right: -4px;
}

.tooltip[data-popper-placement^="right"] > .tooltip-arrow {
  left: -4px;
}

.tooltip.invisible > .tooltip-arrow:before {
  visibility: hidden;
}

[data-popper-arrow], [data-popper-arrow]:before {
  background: inherit;
  width: 8px;
  height: 8px;
  position: absolute;
}

[data-popper-arrow] {
  visibility: hidden;
}

[data-popper-arrow]:before {
  content: "";
  visibility: visible;
  transform: rotate(45deg);
}

[data-popper-arrow]:after {
  content: "";
  visibility: visible;
  background: inherit;
  width: 9px;
  height: 9px;
  position: absolute;
  transform: rotate(45deg);
}

[role="tooltip"] > [data-popper-arrow]:before {
  border-style: solid;
  border-color: var(--color-gray-200);
}

.dark [role="tooltip"] > [data-popper-arrow]:before {
  border-style: solid;
  border-color: var(--color-gray-600);
}

[role="tooltip"] > [data-popper-arrow]:after {
  border-style: solid;
  border-color: var(--color-gray-200);
}

.dark [role="tooltip"] > [data-popper-arrow]:after {
  border-style: solid;
  border-color: var(--color-gray-600);
}

[data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:after {
  border-bottom-width: 1px;
  border-right-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:after {
  border-bottom-width: 1px;
  border-left-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:after {
  border-top-width: 1px;
  border-left-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:after {
  border-top-width: 1px;
  border-right-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow] {
  bottom: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow] {
  top: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow] {
  right: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow] {
  left: -5px;
}

[role="tooltip"].invisible > [data-popper-arrow]:before, [role="tooltip"].invisible > [data-popper-arrow]:after {
  visibility: hidden;
}

.text-primary {
  color: rgba(0, 0, 0, .87);
}

.text-primary-default {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity, 1));
}

.text-no-shadow {
  text-shadow: none;
}

.text-2xs {
  font-size: .6rem;
  line-height: .7rem;
}

.max-w-initial {
  max-width: initial;
}

.outline-6 {
  outline-width: 6px;
}

.border-1 {
  border-width: 1px;
}

.border-3 {
  border-width: 3px;
}

.border-y-1 {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-t-1 {
  border-top-width: 1px;
}

.border-r-1 {
  border-right-width: 1px;
}

.border-b-1 {
  border-bottom-width: 1px;
}

.border-l-1 {
  border-left-width: 1px;
}

.divide-x-1 > * + * {
  border-left-width: 1px;
  border-right-width: 0;
}

.duration-400 {
  transition-duration: .4s;
}

.duration-600 {
  transition-duration: .6s;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 900px) {
  .container {
    max-width: 900px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.dark .selectedCell {
  background-color: var(--color-gray-700);
}

.dark .datatable-wrapper .datatable-search .datatable-input, .dark .datatable-wrapper .datatable-input {
  color: #fff;
  background-color: var(--color-gray-800);
  border: 1px solid var(--color-gray-700);
}

.dark .datatable-wrapper thead th .datatable-input {
  background-color: var(--color-gray-700);
  border-color: var(--color-gray-600);
  color: #fff;
}

.dark .datatable-wrapper .datatable-top .datatable-dropdown {
  color: var(--color-gray-400);
}

.dark .datatable-wrapper .datatable-top .datatable-dropdown .datatable-selector {
  background-color: var(--color-gray-800);
  border: 1px solid var(--color-gray-700);
  color: #fff;
}

.dark .datatable-wrapper .datatable-table {
  color: var(--color-gray-400);
}

.dark .datatable-wrapper .datatable-table thead {
  color: var(--color-gray-400);
  background-color: var(--color-gray-800);
}

.dark .datatable-wrapper .datatable-table thead th .datatable-sorter:hover, .dark .datatable-wrapper .datatable-table thead th.datatable-ascending .datatable-sorter, .dark .datatable-wrapper .datatable-table thead th.datatable-descending .datatable-sorter {
  color: #fff;
}

.datatable-wrapper .datatable-table tbody tr.selected {
  background-color: var(--color-gray-100);
}

.dark .datatable-wrapper .datatable-table tbody tr.selected {
  background-color: var(--color-gray-700);
}

.dark .datatable-wrapper .datatable-table tbody tr {
  border-bottom: 1px solid var(--color-gray-700);
}

.dark .datatable-wrapper .datatable-bottom .datatable-info {
  color: var(--color-gray-400);
}

.dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item-link {
  color: var(--color-gray-400);
  border-color: var(--color-gray-700);
}

.dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:first-of-type .datatable-pagination-list-item-link, .dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:last-of-type .datatable-pagination-list-item-link {
  color: rgba(0, 0, 0, 0);
}

.dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:first-of-type .datatable-pagination-list-item-link:after {
  content: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 24 24'%3e %3cpath stroke='%239CA3AF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m14 8-4 4 4 4'/%3e %3c/svg%3e");
}

.dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:first-of-type .datatable-pagination-list-item-link:hover:after {
  content: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 24 24'%3e %3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m14 8-4 4 4 4'/%3e %3c/svg%3e");
}

.dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:last-of-type .datatable-pagination-list-item-link:after {
  content: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 24 24'%3e %3cpath stroke='%239CA3AF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m10 16 4-4-4-4'/%3e %3c/svg%3e");
}

.dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:last-of-type .datatable-pagination-list-item-link:hover:after {
  content: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 24 24'%3e %3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m10 16 4-4-4-4'/%3e %3c/svg%3e");
}

.dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item:first-of-type .datatable-pagination-list-item-link {
  border-left: 1px solid var(--color-gray-700);
}

.dark .datatable-wrapper .datatable-bottom .datatable-pagination .datatable-pagination-list-item-link:hover {
  background-color: var(--color-gray-700);
  color: #fff;
}

.title-h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.title-h2 {
  font-size: 1.5rem;
  line-height: 2rem;
}

.title-h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.button-primary-contained {
  --tw-bg-opacity: 1;
  background-color: rgba(235, 79, 39, var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity, 1));
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .5rem 1.5rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
  display: inline-flex;
}

.button-primary-contained:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 69, 34, var(--tw-bg-opacity, 1));
}

.button-primary-contained:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 213, 204, var(--tw-ring-opacity, 1));
  outline: 2px solid rgba(0, 0, 0, 0);
}

.button-primary-outlined {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(235, 79, 39, var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgba(239, 86, 47, var(--tw-text-opacity, 1));
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .25rem 1.25rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
  display: inline-flex;
}

.button-primary-outlined:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 213, 204, var(--tw-bg-opacity, 1));
}

.button-primary-outlined:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 213, 204, var(--tw-ring-opacity, 1));
  outline: 2px solid rgba(0, 0, 0, 0);
}

.button-primary-outlined-small {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(235, 79, 39, var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgba(239, 86, 47, var(--tw-text-opacity, 1));
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .25rem .75rem;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1rem;
  display: inline-flex;
}

.button-primary-outlined-small:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 213, 204, var(--tw-bg-opacity, 1));
}

.button-primary-outlined-small:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 213, 204, var(--tw-ring-opacity, 1));
  outline: 2px solid rgba(0, 0, 0, 0);
}

.button-primary-link {
  --tw-text-opacity: 1;
  color: rgba(239, 86, 47, var(--tw-text-opacity, 1));
  justify-content: center;
  align-items: center;
  padding: .25rem 1.25rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  display: inline-flex;
}

.button-primary-link:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 213, 204, var(--tw-ring-opacity, 1));
  outline: 2px solid rgba(0, 0, 0, 0);
}

.text-shadow {
  text-shadow: 0 1px 3px #454545;
}

.block-shadow {
  box-shadow: 0 1px 2px rgba(97, 97, 97, .2), 0 2px 4px rgba(97, 97, 97, .2) !important;
}

.bg-shadow {
  background: linear-gradient(to top, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, .3) 70%, rgba(0, 0, 0, 0) 100%);
}

.divider {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity, 1));
  flex-grow: 1;
  width: 100%;
  height: 1px;
}

.divider-content {
  white-space: nowrap;
  flex-direction: row;
  align-items: center;
  height: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.divider-content:before, .divider-content:after {
  content: "";
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity, 1));
  flex-grow: 1;
  width: 100%;
  height: 1px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.z-tooltip {
  z-index: 30;
}

.z-dropdown {
  z-index: 1150;
}

.z-appbar {
  z-index: 1101;
}

.z-modal-background {
  z-index: 1102;
}

.z-modal {
  z-index: 1110;
}

.z-modal-background-connection {
  z-index: 1129;
}

.z-modal-connection {
  z-index: 1130;
}

.z-drawer-background {
  z-index: 1200;
}

.z-drawer {
  z-index: 1210;
}

.z-notification {
  z-index: 1300;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-bottom-5 {
  bottom: -1.25rem;
}

.-left-2 {
  left: -.5rem;
}

.-right-2 {
  right: -.5rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-2\.5 {
  bottom: .625rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-full {
  bottom: 100%;
}

.end-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  right: 0;
}

.end-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  right: 0;
}

.end-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.end-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.end-2\.5:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  right: .625rem;
}

.end-2\.5:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  right: .625rem;
}

.end-2\.5:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: .625rem;
}

.end-2\.5:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: .625rem;
}

.left-0 {
  left: 0;
}

.left-5 {
  left: 1.25rem;
}

.left-\[15px\] {
  left: 15px;
}

.right-0 {
  right: 0;
}

.right-\[-12px\] {
  right: -12px;
}

.start-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  left: 0;
}

.start-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  left: 0;
}

.start-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.start-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-1\/2 {
  top: 50%;
}

.top-2\.5 {
  top: .625rem;
}

.top-\[-12px\] {
  top: -12px;
}

.top-\[-3px\] {
  top: -3px;
}

.top-auto {
  top: auto;
}

.z-50 {
  z-index: 50;
}

.-mx-1\.5 {
  margin-left: -.375rem;
  margin-right: -.375rem;
}

.-my-1\.5 {
  margin-top: -.375rem;
  margin-bottom: -.375rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.-mt-9 {
  margin-top: -2.25rem;
}

.-mt-px {
  margin-top: -1px;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-2\.5 {
  margin-bottom: .625rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.me-1:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: .25rem;
}

.me-1:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: .25rem;
}

.me-1:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .25rem;
}

.me-1:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .25rem;
}

.me-1\.5:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: .375rem;
}

.me-1\.5:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: .375rem;
}

.me-1\.5:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .375rem;
}

.me-1\.5:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .375rem;
}

.me-2:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: .5rem;
}

.me-2:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: .5rem;
}

.me-2:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .5rem;
}

.me-2:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .5rem;
}

.me-3:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: .75rem;
}

.me-3:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: .75rem;
}

.me-3:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .75rem;
}

.me-3:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: .75rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-3 {
  margin-right: .75rem;
}

.ms-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.ms-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 0;
}

.ms-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.ms-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 0;
}

.ms-1\.5:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .375rem;
}

.ms-1\.5:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .375rem;
}

.ms-1\.5:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .375rem;
}

.ms-1\.5:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .375rem;
}

.ms-2:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.ms-2:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.ms-2:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.ms-2:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.ms-2\.5:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .625rem;
}

.ms-2\.5:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .625rem;
}

.ms-2\.5:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .625rem;
}

.ms-2\.5:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .625rem;
}

.ms-3:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .75rem;
}

.ms-3:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .75rem;
}

.ms-3:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .75rem;
}

.ms-3:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .75rem;
}

.ms-4:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.ms-4:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1rem;
}

.ms-4:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.ms-4:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1rem;
}

.ms-7:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1.75rem;
}

.ms-7:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: 1.75rem;
}

.ms-7:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1.75rem;
}

.ms-7:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: 1.75rem;
}

.ms-auto:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.ms-auto:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: auto;
}

.ms-auto:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.ms-auto:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: auto;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[2px\] {
  margin-top: 2px;
}

.mt-px {
  margin-top: 1px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-12 {
  height: 3rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-20 {
  height: 5rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[calc\(100\%-1rem\)\] {
  height: calc(100% - 1rem);
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-32 {
  max-height: 8rem;
}

.max-h-\[503px\] {
  max-height: 503px;
}

.max-h-full {
  max-height: 100%;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-12 {
  width: 3rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/5 {
  width: 80%;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -webkit-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.min-w-48 {
  min-width: 12rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.basis-28 {
  flex-basis: 7rem;
}

.border-collapse {
  border-collapse: collapse;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[150\%\] {
  --tw-translate-y: -150%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-ping {
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.list-none {
  list-style-type: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-divide-opacity, 1));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity, 1));
}

.divide-primary-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(235, 79, 39, var(--tw-divide-opacity, 1));
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-e-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-e-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-e-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-e-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-s-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-s-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-s-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-s-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-e-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-right-width: 0;
}

.border-e-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-right-width: 0;
}

.border-e-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-left-width: 0;
}

.border-e-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-left-width: 0;
}

.border-t {
  border-top-width: 1px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(164, 202, 254, var(--tw-border-opacity, 1));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity, 1));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity, 1));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity, 1));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity, 1));
}

.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(5, 122, 85, var(--tw-border-opacity, 1));
}

.border-primary-300 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 213, 204, var(--tw-border-opacity, 1));
}

.border-primary-400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 188, 173, var(--tw-border-opacity, 1));
}

.border-primary-600 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 86, 47, var(--tw-border-opacity, 1));
}

.border-primary-700 {
  --tw-border-opacity: 1;
  border-color: rgba(235, 79, 39, var(--tw-border-opacity, 1));
}

.border-primary-900 {
  --tw-border-opacity: 1;
  border-color: rgba(165, 55, 27, var(--tw-border-opacity, 1));
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgba(248, 180, 180, var(--tw-border-opacity, 1));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(240, 82, 82, var(--tw-border-opacity, 1));
}

.border-secondary-700 {
  --tw-border-opacity: 1;
  border-color: rgba(5, 139, 92, var(--tw-border-opacity, 1));
}

.border-teal-700 {
  --tw-border-opacity: 1;
  border-color: rgba(3, 102, 114, var(--tw-border-opacity, 1));
}

.border-transparent {
  border-color: rgba(0, 0, 0, 0);
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity, 1));
}

.border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgba(250, 202, 21, var(--tw-border-opacity, 1));
}

.border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgba(114, 59, 19, var(--tw-border-opacity, 1));
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(235, 245, 255, var(--tw-bg-opacity, 1));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 131, 248, var(--tw-bg-opacity, 1));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 86, 219, var(--tw-bg-opacity, 1));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity, 1));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity, 1));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity, 1));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity, 1));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity, 1));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity, 1));
}

.bg-gray-900\/50 {
  background-color: rgba(17, 24, 39, .5);
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 250, 247, var(--tw-bg-opacity, 1));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 122, 85, var(--tw-bg-opacity, 1));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 108, 78, var(--tw-bg-opacity, 1));
}

.bg-orange-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 52, 3, var(--tw-bg-opacity, 1));
}

.bg-primary-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 241, 238, var(--tw-bg-opacity, 1));
}

.bg-primary-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 228, 222, var(--tw-bg-opacity, 1));
}

.bg-primary-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 213, 204, var(--tw-bg-opacity, 1));
}

.bg-primary-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 245, 242, var(--tw-bg-opacity, 1));
}

.bg-primary-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 121, 93, var(--tw-bg-opacity, 1));
}

.bg-primary-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 86, 47, var(--tw-bg-opacity, 1));
}

.bg-primary-600\/15 {
  background-color: rgba(239, 86, 47, .15);
}

.bg-primary-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(235, 79, 39, var(--tw-bg-opacity, 1));
}

.bg-primary-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 69, 34, var(--tw-bg-opacity, 1));
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(108, 43, 217, var(--tw-bg-opacity, 1));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 242, var(--tw-bg-opacity, 1));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 82, 82, var(--tw-bg-opacity, 1));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 36, 36, var(--tw-bg-opacity, 1));
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(200, 30, 30, var(--tw-bg-opacity, 1));
}

.bg-secondary-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(151, 212, 184, var(--tw-bg-opacity, 1));
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity, 1));
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 160, 8, var(--tw-bg-opacity, 1));
}

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 253, 234, var(--tw-bg-opacity, 1));
}

.fill-blue-600 {
  fill: #1c64f2;
}

.fill-gray-600 {
  fill: #4b5563;
}

.fill-green-500 {
  fill: #0e9f6e;
}

.fill-pink-600 {
  fill: #d61f69;
}

.fill-primary-600 {
  fill: #ef562f;
}

.fill-purple-600 {
  fill: #7e3af2;
}

.fill-red-600 {
  fill: #e02424;
}

.fill-white {
  fill: #fff;
}

.fill-yellow-400 {
  fill: #e3a008;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-11 {
  padding-left: 2.75rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pr-1 {
  padding-right: .25rem;
}

.ps-2\.5:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: .625rem;
}

.ps-2\.5:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: .625rem;
}

.ps-2\.5:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: .625rem;
}

.ps-2\.5:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: .625rem;
}

.ps-3:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: .75rem;
}

.ps-3:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: .75rem;
}

.ps-3:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: .75rem;
}

.ps-3:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: .75rem;
}

.ps-4:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: 1rem;
}

.ps-4:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: 1rem;
}

.ps-4:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 1rem;
}

.ps-4:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 1rem;
}

.ps-9:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: 2.25rem;
}

.ps-9:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: 2.25rem;
}

.ps-9:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 2.25rem;
}

.ps-9:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 2.25rem;
}

.pt-3 {
  padding-top: .75rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.align-middle {
  vertical-align: middle;
}

.font-sans {
  font-family: Open Sans, Helvetica, Arial, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.leading-3 {
  line-height: .75rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-normal {
  line-height: 1.5;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity, 1));
}

.text-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(195, 221, 253, var(--tw-text-opacity, 1));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(28, 100, 242, var(--tw-text-opacity, 1));
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 66, 159, var(--tw-text-opacity, 1));
}

.text-error {
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity, 1));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity, 1));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity, 1));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity, 1));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity, 1));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity, 1));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity, 1));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity, 1));
}

.text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(188, 240, 218, var(--tw-text-opacity, 1));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(5, 122, 85, var(--tw-text-opacity, 1));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(3, 84, 63, var(--tw-text-opacity, 1));
}

.text-link {
  --tw-text-opacity: 1;
  color: rgba(91, 150, 236, var(--tw-text-opacity, 1));
}

.text-orange-200 {
  --tw-text-opacity: 1;
  color: rgba(252, 217, 189, var(--tw-text-opacity, 1));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 90, 31, var(--tw-text-opacity, 1));
}

.text-primary-200 {
  --tw-text-opacity: 1;
  color: rgba(255, 228, 222, var(--tw-text-opacity, 1));
}

.text-primary-500 {
  --tw-text-opacity: 1;
  color: rgba(254, 121, 93, var(--tw-text-opacity, 1));
}

.text-primary-600 {
  --tw-text-opacity: 1;
  color: rgba(239, 86, 47, var(--tw-text-opacity, 1));
}

.text-primary-700 {
  --tw-text-opacity: 1;
  color: rgba(235, 79, 39, var(--tw-text-opacity, 1));
}

.text-primary-800 {
  --tw-text-opacity: 1;
  color: rgba(204, 69, 34, var(--tw-text-opacity, 1));
}

.text-primary-900 {
  --tw-text-opacity: 1;
  color: rgba(165, 55, 27, var(--tw-text-opacity, 1));
}

.text-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(220, 215, 254, var(--tw-text-opacity, 1));
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(126, 58, 242, var(--tw-text-opacity, 1));
}

.text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 213, 213, var(--tw-text-opacity, 1));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(224, 36, 36, var(--tw-text-opacity, 1));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(200, 30, 30, var(--tw-text-opacity, 1));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(155, 28, 28, var(--tw-text-opacity, 1));
}

.text-secondary-200 {
  --tw-text-opacity: 1;
  color: rgba(151, 212, 184, var(--tw-text-opacity, 1));
}

.text-secondary-600 {
  --tw-text-opacity: 1;
  color: rgba(13, 157, 105, var(--tw-text-opacity, 1));
}

.text-teal-200 {
  --tw-text-opacity: 1;
  color: rgba(175, 236, 239, var(--tw-text-opacity, 1));
}

.text-teal-600 {
  --tw-text-opacity: 1;
  color: rgba(4, 116, 129, var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity, 1));
}

.text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgba(252, 233, 106, var(--tw-text-opacity, 1));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(227, 160, 8, var(--tw-text-opacity, 1));
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgba(114, 59, 19, var(--tw-text-opacity, 1));
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.line-through {
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.opacity-75 {
  opacity: .75;
}

.opacity-\[0\.45\] {
  opacity: .45;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 8px 10px -6px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.outline {
  outline-style: solid;
}

.outline-1 {
  outline-width: 1px;
}

.outline-blue-800 {
  outline-color: #1e429f;
}

.outline-green-800 {
  outline-color: #03543f;
}

.outline-primary-600\/30 {
  outline-color: rgba(239, 86, 47, .3);
}

.outline-red-800 {
  outline-color: #9b1c1c;
}

.outline-yellow-800 {
  outline-color: #723b13;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, .1)) drop-shadow(0 1px 1px rgba(0, 0, 0, .06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-filter {
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.notification.notification-info {
  --tw-border-opacity: 1;
  border-color: rgba(164, 202, 254, var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgba(235, 245, 255, var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgba(30, 66, 159, var(--tw-text-opacity, 1));
  outline-color: #1e429f;
}

.notification.notification-info:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(30, 66, 159, var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgba(118, 169, 250, var(--tw-text-opacity, 1));
}

.notification.notification-success {
  --tw-border-opacity: 1;
  border-color: rgba(5, 122, 85, var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgba(243, 250, 247, var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgba(3, 84, 63, var(--tw-text-opacity, 1));
  outline-color: #03543f;
}

.notification.notification-success:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(3, 84, 63, var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgba(49, 196, 141, var(--tw-text-opacity, 1));
}

.notification.notification-warning {
  --tw-border-opacity: 1;
  border-color: rgba(250, 202, 21, var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgba(253, 253, 234, var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgba(114, 59, 19, var(--tw-text-opacity, 1));
  outline-color: #723b13;
}

.notification.notification-warning:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(114, 59, 19, var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgba(250, 202, 21, var(--tw-text-opacity, 1));
}

.notification.notification-error {
  --tw-border-opacity: 1;
  border-color: rgba(248, 180, 180, var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 242, var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgba(155, 28, 28, var(--tw-text-opacity, 1));
  outline-color: #9b1c1c;
}

.notification.notification-error:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(114, 59, 19, var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgba(248, 180, 180, var(--tw-text-opacity, 1));
}

.text-formatted {
  text-justify: auto;
  word-wrap: break-word;
  white-space: pre-wrap;
  width: 100%;
}

.bullet-separator {
  justify-content: center;
  align-items: center;
  display: flex;
}

.bullet-separator:not(:last-child):after {
  content: "•";
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity, 1));
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1rem;
  font-weight: 600;
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .bullet-separator:not(:last-child):after {
    margin-left: 5px;
    margin-right: 5px;
    font-size: .7rem;
  }
}

@media only screen and (max-width: 600px) {
  .bullet-separator:not(:last-child):after {
    margin-left: 7px;
    margin-right: 7px;
    font-size: .7rem;
  }
}

.breadcrumb-separator:before {
  content: "";
  opacity: .4;
  background-color: rgba(0, 0, 0, 0);
  border-top: 1px solid;
  border-right: 1px solid;
  width: .375rem;
  height: .375rem;
  display: block;
  transform: rotate(45deg);
}

@font-face {
  font-family: Open Sans;
  src: local(OpenSans-Light), url("@/fonts/open-sans/OpenSans-Light.woff2") format("woff2"), url("@/fonts/open-sans/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  unicode-range: U+20-5F, U+61-7D, U+7C, U+A0-A1, U+A7, U+A9, U+AB, U+B2-B3, U+BB, U+BF, U+C0, U+C1, U+C2, U+C4, U+C6-CB, U+CD-CF, U+D1, U+D3, U+D4, U+D6, U+D9, U+DA-DC, U+DF, U+E0, U+E2, U+E4, U+E6-EB, U+ED, U+EE-EF, U+F1-F3, U+F4, U+F6, U+F9, U+FA-FC, U+FF, U+152-153, U+178, U+2B3, U+2E2, U+1D48-1D49, U+2010-2011, U+2013-2014, U+2018-2019, U+201A, U+201C-201D, U+201E, U+2020-2021, U+2026, U+202F-2030, U+2032-2033, U+20AC, U+2212;
}

@font-face {
  font-family: Open Sans;
  src: local(OpenSans-LightItalic), url("@/fonts/open-sans/OpenSans-LightItalic.woff2") format("woff2"), url("@/fonts/open-sans/OpenSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  unicode-range: U+20-5F, U+61-7D, U+7C, U+A0-A1, U+A7, U+A9, U+AB, U+B2-B3, U+BB, U+BF, U+C0, U+C1, U+C2, U+C4, U+C6-CB, U+CD-CF, U+D1, U+D3, U+D4, U+D6, U+D9, U+DA-DC, U+DF, U+E0, U+E2, U+E4, U+E6-EB, U+ED, U+EE-EF, U+F1-F3, U+F4, U+F6, U+F9, U+FA-FC, U+FF, U+152-153, U+178, U+2B3, U+2E2, U+1D48-1D49, U+2010-2011, U+2013-2014, U+2018-2019, U+201A, U+201C-201D, U+201E, U+2020-2021, U+2026, U+202F-2030, U+2032-2033, U+20AC, U+2212;
}

@font-face {
  font-family: Open Sans;
  src: local(OpenSans-Regular), url("@/fonts/open-sans/OpenSans-Regular.woff2") format("woff2"), url("@/fonts/open-sans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+20-5F, U+61-7D, U+7C, U+A0-A1, U+A7, U+A9, U+AB, U+B2-B3, U+BB, U+BF, U+C0, U+C1, U+C2, U+C4, U+C6-CB, U+CD-CF, U+D1, U+D3, U+D4, U+D6, U+D9, U+DA-DC, U+DF, U+E0, U+E2, U+E4, U+E6-EB, U+ED, U+EE-EF, U+F1-F3, U+F4, U+F6, U+F9, U+FA-FC, U+FF, U+152-153, U+178, U+2B3, U+2E2, U+1D48-1D49, U+2010-2011, U+2013-2014, U+2018-2019, U+201A, U+201C-201D, U+201E, U+2020-2021, U+2026, U+202F-2030, U+2032-2033, U+20AC, U+2212;
}

@font-face {
  font-family: Open Sans;
  src: local(OpenSans-Italic), url("@/fonts/open-sans/OpenSans-Italic.woff2") format("woff2"), url("@/fonts/open-sans/OpenSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  unicode-range: U+20-5F, U+61-7D, U+7C, U+A0-A1, U+A7, U+A9, U+AB, U+B2-B3, U+BB, U+BF, U+C0, U+C1, U+C2, U+C4, U+C6-CB, U+CD-CF, U+D1, U+D3, U+D4, U+D6, U+D9, U+DA-DC, U+DF, U+E0, U+E2, U+E4, U+E6-EB, U+ED, U+EE-EF, U+F1-F3, U+F4, U+F6, U+F9, U+FA-FC, U+FF, U+152-153, U+178, U+2B3, U+2E2, U+1D48-1D49, U+2010-2011, U+2013-2014, U+2018-2019, U+201A, U+201C-201D, U+201E, U+2020-2021, U+2026, U+202F-2030, U+2032-2033, U+20AC, U+2212;
}

@font-face {
  font-family: Open Sans;
  src: local(OpenSans-Semibold), url("@/fonts/open-sans/OpenSans-Semibold.woff2") format("woff2"), url("@/fonts/open-sans/OpenSans-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  unicode-range: U+20-5F, U+61-7D, U+7C, U+A0-A1, U+A7, U+A9, U+AB, U+B2-B3, U+BB, U+BF, U+C0, U+C1, U+C2, U+C4, U+C6-CB, U+CD-CF, U+D1, U+D3, U+D4, U+D6, U+D9, U+DA-DC, U+DF, U+E0, U+E2, U+E4, U+E6-EB, U+ED, U+EE-EF, U+F1-F3, U+F4, U+F6, U+F9, U+FA-FC, U+FF, U+152-153, U+178, U+2B3, U+2E2, U+1D48-1D49, U+2010-2011, U+2013-2014, U+2018-2019, U+201A, U+201C-201D, U+201E, U+2020-2021, U+2026, U+202F-2030, U+2032-2033, U+20AC, U+2212;
}

@font-face {
  font-family: Open Sans;
  src: local(OpenSans-SemiboldItalic), url("@/fonts/open-sans/OpenSans-SemiboldItalic.woff2") format("woff2"), url("@/fonts/open-sans/OpenSans-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  unicode-range: U+20-5F, U+61-7D, U+7C, U+A0-A1, U+A7, U+A9, U+AB, U+B2-B3, U+BB, U+BF, U+C0, U+C1, U+C2, U+C4, U+C6-CB, U+CD-CF, U+D1, U+D3, U+D4, U+D6, U+D9, U+DA-DC, U+DF, U+E0, U+E2, U+E4, U+E6-EB, U+ED, U+EE-EF, U+F1-F3, U+F4, U+F6, U+F9, U+FA-FC, U+FF, U+152-153, U+178, U+2B3, U+2E2, U+1D48-1D49, U+2010-2011, U+2013-2014, U+2018-2019, U+201A, U+201C-201D, U+201E, U+2020-2021, U+2026, U+202F-2030, U+2032-2033, U+20AC, U+2212;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  color: rgba(0, 0, 0, .87);
  box-sizing: border-box;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

@media only screen and (min-width: 901px) {
  html {
    font-size: 18px;
  }
}

.container {
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
}

@media only screen and (min-width: 901px) {
  .container {
    width: 70%;
  }
}

@media only screen and (max-width: 1200px) {
  .container {
    width: 85%;
  }
}

@media only screen and (min-width: 601px) {
  .container {
    width: 85%;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    width: 90%;
  }
}

.col .row {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.row {
  margin-bottom: 20px;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.row:after {
  content: "";
  clear: both;
  display: table;
}

.row.input-form {
  margin-bottom: 0;
}

.row .col {
  float: left;
  box-sizing: border-box;
  min-height: 1px;
  padding: 0 .75rem;
}

.row .col[class*="push-"], .row .col[class*="pull-"] {
  position: relative;
}

.row .col.s1 {
  width: 8.33333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s2 {
  width: 16.6667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s3 {
  width: 25%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s4 {
  width: 33.3333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s5 {
  width: 41.6667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s6 {
  width: 50%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s7 {
  width: 58.3333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s8 {
  width: 66.6667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s9 {
  width: 75%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s10 {
  width: 83.3333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s11 {
  width: 91.6667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.s12 {
  width: 100%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.offset-s1 {
  margin-left: 8.33333%;
}

.row .col.pull-s1 {
  right: 8.33333%;
}

.row .col.push-s1 {
  left: 8.33333%;
}

.row .col.offset-s2 {
  margin-left: 16.6667%;
}

.row .col.pull-s2 {
  right: 16.6667%;
}

.row .col.push-s2 {
  left: 16.6667%;
}

.row .col.offset-s3 {
  margin-left: 25%;
}

.row .col.pull-s3 {
  right: 25%;
}

.row .col.push-s3 {
  left: 25%;
}

.row .col.offset-s4 {
  margin-left: 33.3333%;
}

.row .col.pull-s4 {
  right: 33.3333%;
}

.row .col.push-s4 {
  left: 33.3333%;
}

.row .col.offset-s5 {
  margin-left: 41.6667%;
}

.row .col.pull-s5 {
  right: 41.6667%;
}

.row .col.push-s5 {
  left: 41.6667%;
}

.row .col.offset-s6 {
  margin-left: 50%;
}

.row .col.pull-s6 {
  right: 50%;
}

.row .col.push-s6 {
  left: 50%;
}

.row .col.offset-s7 {
  margin-left: 58.3333%;
}

.row .col.pull-s7 {
  right: 58.3333%;
}

.row .col.push-s7 {
  left: 58.3333%;
}

.row .col.offset-s8 {
  margin-left: 66.6667%;
}

.row .col.pull-s8 {
  right: 66.6667%;
}

.row .col.push-s8 {
  left: 66.6667%;
}

.row .col.offset-s9 {
  margin-left: 75%;
}

.row .col.pull-s9 {
  right: 75%;
}

.row .col.push-s9 {
  left: 75%;
}

.row .col.offset-s10 {
  margin-left: 83.3333%;
}

.row .col.pull-s10 {
  right: 83.3333%;
}

.row .col.push-s10 {
  left: 83.3333%;
}

.row .col.offset-s11 {
  margin-left: 91.6667%;
}

.row .col.pull-s11 {
  right: 91.6667%;
}

.row .col.push-s11 {
  left: 91.6667%;
}

.row .col.offset-s12 {
  margin-left: 100%;
}

.row .col.pull-s12 {
  right: 100%;
}

.row .col.push-s12 {
  left: 100%;
}

@media only screen and (min-width: 601px) {
  .row .col.m1 {
    width: 8.33333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m2 {
    width: 16.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m4 {
    width: 33.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m5 {
    width: 41.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m7 {
    width: 58.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m8 {
    width: 66.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m10 {
    width: 83.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m11 {
    width: 91.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.m12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.offset-m1 {
    margin-left: 8.33333%;
  }

  .row .col.pull-m1 {
    right: 8.33333%;
  }

  .row .col.push-m1 {
    left: 8.33333%;
  }

  .row .col.offset-m2 {
    margin-left: 16.6667%;
  }

  .row .col.pull-m2 {
    right: 16.6667%;
  }

  .row .col.push-m2 {
    left: 16.6667%;
  }

  .row .col.offset-m3 {
    margin-left: 25%;
  }

  .row .col.pull-m3 {
    right: 25%;
  }

  .row .col.push-m3 {
    left: 25%;
  }

  .row .col.offset-m4 {
    margin-left: 33.3333%;
  }

  .row .col.pull-m4 {
    right: 33.3333%;
  }

  .row .col.push-m4 {
    left: 33.3333%;
  }

  .row .col.offset-m5 {
    margin-left: 41.6667%;
  }

  .row .col.pull-m5 {
    right: 41.6667%;
  }

  .row .col.push-m5 {
    left: 41.6667%;
  }

  .row .col.offset-m6 {
    margin-left: 50%;
  }

  .row .col.pull-m6 {
    right: 50%;
  }

  .row .col.push-m6 {
    left: 50%;
  }

  .row .col.offset-m7 {
    margin-left: 58.3333%;
  }

  .row .col.pull-m7 {
    right: 58.3333%;
  }

  .row .col.push-m7 {
    left: 58.3333%;
  }

  .row .col.offset-m8 {
    margin-left: 66.6667%;
  }

  .row .col.pull-m8 {
    right: 66.6667%;
  }

  .row .col.push-m8 {
    left: 66.6667%;
  }

  .row .col.offset-m9 {
    margin-left: 75%;
  }

  .row .col.pull-m9 {
    right: 75%;
  }

  .row .col.push-m9 {
    left: 75%;
  }

  .row .col.offset-m10 {
    margin-left: 83.3333%;
  }

  .row .col.pull-m10 {
    right: 83.3333%;
  }

  .row .col.push-m10 {
    left: 83.3333%;
  }

  .row .col.offset-m11 {
    margin-left: 91.6667%;
  }

  .row .col.pull-m11 {
    right: 91.6667%;
  }

  .row .col.push-m11 {
    left: 91.6667%;
  }

  .row .col.offset-m12 {
    margin-left: 100%;
  }

  .row .col.pull-m12 {
    right: 100%;
  }

  .row .col.push-m12 {
    left: 100%;
  }

  .row .col .col.offset-m0 {
    margin-left: 0;
  }
}

@media only screen and (min-width: 901px) {
  .row .col.l1 {
    width: 8.33333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l2 {
    width: 16.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l4 {
    width: 33.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l5 {
    width: 41.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l7 {
    width: 58.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l8 {
    width: 66.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l10 {
    width: 83.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l11 {
    width: 91.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.l12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.offset-l1 {
    margin-left: 8.33333%;
  }

  .row .col.pull-l1 {
    right: 8.33333%;
  }

  .row .col.push-l1 {
    left: 8.33333%;
  }

  .row .col.offset-l2 {
    margin-left: 16.6667%;
  }

  .row .col.pull-l2 {
    right: 16.6667%;
  }

  .row .col.push-l2 {
    left: 16.6667%;
  }

  .row .col.offset-l3 {
    margin-left: 25%;
  }

  .row .col.pull-l3 {
    right: 25%;
  }

  .row .col.push-l3 {
    left: 25%;
  }

  .row .col.offset-l4 {
    margin-left: 33.3333%;
  }

  .row .col.pull-l4 {
    right: 33.3333%;
  }

  .row .col.push-l4 {
    left: 33.3333%;
  }

  .row .col.offset-l5 {
    margin-left: 41.6667%;
  }

  .row .col.pull-l5 {
    right: 41.6667%;
  }

  .row .col.push-l5 {
    left: 41.6667%;
  }

  .row .col.offset-l6 {
    margin-left: 50%;
  }

  .row .col.pull-l6 {
    right: 50%;
  }

  .row .col.push-l6 {
    left: 50%;
  }

  .row .col.offset-l7 {
    margin-left: 58.3333%;
  }

  .row .col.pull-l7 {
    right: 58.3333%;
  }

  .row .col.push-l7 {
    left: 58.3333%;
  }

  .row .col.offset-l8 {
    margin-left: 66.6667%;
  }

  .row .col.pull-l8 {
    right: 66.6667%;
  }

  .row .col.push-l8 {
    left: 66.6667%;
  }

  .row .col.offset-l9 {
    margin-left: 75%;
  }

  .row .col.pull-l9 {
    right: 75%;
  }

  .row .col.push-l9 {
    left: 75%;
  }

  .row .col.offset-l10 {
    margin-left: 83.3333%;
  }

  .row .col.pull-l10 {
    right: 83.3333%;
  }

  .row .col.push-l10 {
    left: 83.3333%;
  }

  .row .col.offset-l11 {
    margin-left: 91.6667%;
  }

  .row .col.pull-l11 {
    right: 91.6667%;
  }

  .row .col.push-l11 {
    left: 91.6667%;
  }

  .row .col.offset-l12 {
    margin-left: 100%;
  }

  .row .col.pull-l12 {
    right: 100%;
  }

  .row .col.push-l12 {
    left: 100%;
  }

  .row .col .col.offset-l0 {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1536px) {
  .row .col.xl1 {
    width: 8.33333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl2 {
    width: 16.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl4 {
    width: 33.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl5 {
    width: 41.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl7 {
    width: 58.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl8 {
    width: 66.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl10 {
    width: 83.3333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl11 {
    width: 91.6667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.offset-xl1 {
    margin-left: 8.33333%;
  }

  .row .col.pull-xl1 {
    right: 8.33333%;
  }

  .row .col.push-xl1 {
    left: 8.33333%;
  }

  .row .col.offset-xl2 {
    margin-left: 16.6667%;
  }

  .row .col.pull-xl2 {
    right: 16.6667%;
  }

  .row .col.push-xl2 {
    left: 16.6667%;
  }

  .row .col.offset-xl3 {
    margin-left: 25%;
  }

  .row .col.pull-xl3 {
    right: 25%;
  }

  .row .col.push-xl3 {
    left: 25%;
  }

  .row .col.offset-xl4 {
    margin-left: 33.3333%;
  }

  .row .col.pull-xl4 {
    right: 33.3333%;
  }

  .row .col.push-xl4 {
    left: 33.3333%;
  }

  .row .col.offset-xl5 {
    margin-left: 41.6667%;
  }

  .row .col.pull-xl5 {
    right: 41.6667%;
  }

  .row .col.push-xl5 {
    left: 41.6667%;
  }

  .row .col.offset-xl6 {
    margin-left: 50%;
  }

  .row .col.pull-xl6 {
    right: 50%;
  }

  .row .col.push-xl6 {
    left: 50%;
  }

  .row .col.offset-xl7 {
    margin-left: 58.3333%;
  }

  .row .col.pull-xl7 {
    right: 58.3333%;
  }

  .row .col.push-xl7 {
    left: 58.3333%;
  }

  .row .col.offset-xl8 {
    margin-left: 66.6667%;
  }

  .row .col.pull-xl8 {
    right: 66.6667%;
  }

  .row .col.push-xl8 {
    left: 66.6667%;
  }

  .row .col.offset-xl9 {
    margin-left: 75%;
  }

  .row .col.pull-xl9 {
    right: 75%;
  }

  .row .col.push-xl9 {
    left: 75%;
  }

  .row .col.offset-xl10 {
    margin-left: 83.3333%;
  }

  .row .col.pull-xl10 {
    right: 83.3333%;
  }

  .row .col.push-xl10 {
    left: 83.3333%;
  }

  .row .col.offset-xl11 {
    margin-left: 91.6667%;
  }

  .row .col.pull-xl11 {
    right: 91.6667%;
  }

  .row .col.push-xl11 {
    left: 91.6667%;
  }

  .row .col.offset-xl12 {
    margin-left: 100%;
  }

  .row .col.pull-xl12 {
    right: 100%;
  }

  .row .col.push-xl12 {
    left: 100%;
  }
}

.margin-bottom-0 {
  clear: both;
  margin-bottom: 0 !important;
}

.margin-top-0 {
  clear: both;
  margin-top: 0 !important;
}

.margin-bottom-5 {
  clear: both;
  margin-bottom: 5px !important;
}

.margin-top-5 {
  clear: both;
  margin-top: 5px !important;
}

.margin-bottom-10 {
  clear: both;
  margin-bottom: 10px !important;
}

.margin-top-10 {
  clear: both;
  margin-top: 10px !important;
}

.margin-bottom-15 {
  clear: both;
  margin-bottom: 15px !important;
}

.margin-top-15 {
  clear: both;
  margin-top: 15px !important;
}

.margin-bottom-20 {
  clear: both;
  margin-bottom: 20px !important;
}

.margin-top-20 {
  clear: both;
  margin-top: 20px !important;
}

.margin-bottom-25 {
  clear: both;
  margin-bottom: 25px !important;
}

.margin-top-25 {
  clear: both;
  margin-top: 25px !important;
}

.margin-bottom-30 {
  clear: both;
  margin-bottom: 30px !important;
}

.margin-top-30 {
  clear: both;
  margin-top: 30px !important;
}

.margin-bottom-35 {
  clear: both;
  margin-bottom: 35px !important;
}

.margin-top-35 {
  clear: both;
  margin-top: 35px !important;
}

.margin-bottom-40 {
  clear: both;
  margin-bottom: 40px !important;
}

.margin-top-40 {
  clear: both;
  margin-top: 40px !important;
}

.margin-bottom-45 {
  clear: both;
  margin-bottom: 45px !important;
}

.margin-top-45 {
  clear: both;
  margin-top: 45px !important;
}

.margin-bottom-50 {
  clear: both;
  margin-bottom: 50px !important;
}

.margin-top-50 {
  clear: both;
  margin-top: 50px !important;
}

.margin-bottom-55 {
  clear: both;
  margin-bottom: 55px !important;
}

.margin-top-55 {
  clear: both;
  margin-top: 55px !important;
}

.margin-bottom-60 {
  clear: both;
  margin-bottom: 60px !important;
}

.margin-top-60 {
  clear: both;
  margin-top: 60px !important;
}

.margin-bottom-65 {
  clear: both;
  margin-bottom: 65px !important;
}

.margin-top-65 {
  clear: both;
  margin-top: 65px !important;
}

.margin-bottom-70 {
  clear: both;
  margin-bottom: 70px !important;
}

.margin-top-70 {
  clear: both;
  margin-top: 70px !important;
}

.margin-bottom-75 {
  clear: both;
  margin-bottom: 75px !important;
}

.margin-top-75 {
  clear: both;
  margin-top: 75px !important;
}

.margin-bottom-80 {
  clear: both;
  margin-bottom: 80px !important;
}

.margin-top-80 {
  clear: both;
  margin-top: 80px !important;
}

.margin-bottom-85 {
  clear: both;
  margin-bottom: 85px !important;
}

.margin-top-85 {
  clear: both;
  margin-top: 85px !important;
}

.margin-bottom-90 {
  clear: both;
  margin-bottom: 90px !important;
}

.margin-top-90 {
  clear: both;
  margin-top: 90px !important;
}

.margin-bottom-95 {
  clear: both;
  margin-bottom: 95px !important;
}

.margin-top-95 {
  clear: both;
  margin-top: 95px !important;
}

.margin-bottom-100 {
  clear: both;
  margin-bottom: 100px !important;
}

.margin-top-100 {
  clear: both;
  margin-top: 100px !important;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-results-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

h1 {
  color: rgba(0, 0, 0, .87);
  letter-spacing: -.01562em;
  text-align: center;
  text-transform: initial;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.167;
}

h2 {
  color: rgba(0, 0, 0, .87);
  letter-spacing: -.00833em;
  text-transform: initial;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  font-size: 2.6rem;
  font-weight: 600;
  line-height: 1.2;
}

h3 {
  color: rgba(0, 0, 0, .87);
  letter-spacing: -.00833em;
  text-transform: initial;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  font-size: 2.3625rem;
  font-weight: 400;
  line-height: 1.167;
}

h4 {
  color: rgba(0, 0, 0, .87);
  letter-spacing: .00735em;
  text-transform: initial;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  font-size: 2.125rem;
  font-weight: 400;
  line-height: 1.235;
}

h5 {
  color: rgba(0, 0, 0, .87);
  letter-spacing: 0;
  text-transform: initial;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.334;
}

h6 {
  color: rgba(0, 0, 0, .87);
  letter-spacing: .0075em;
  text-transform: initial;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
}

.language-picker-flag {
  display: flex;
}

.language-picker-flag:before {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid rgba(0, 0, 0, .15);
  width: 16px;
  height: 16px;
  margin-right: .5rem;
  display: inline-block;
}

.language-picker-flag--fr:before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANBAMAAABbflNtAAAAD1BMVEUAI5VUa7jtKTnzcHv////gD0QlAAAAFklEQVQI12NgYGAQcXFxVlJSYhgETACHHRCCY3/exgAAAABJRU5ErkJggg==");
}

.language-picker-flag--be:before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARBAMAAAAvapGNAAAAElBMVEUAAABUSQzvM0Dzazb0azf92iXtM4hdAAAAG0lEQVQI12NgYGAQDQ0NUVJSYoAwg4cCE+ReAAKsGPt3Iqi1AAAAAElFTkSuQmCC");
}

.language-picker-flag--us:before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALBAMAAACNJ7BwAAAAMFBMVEXovcLBS1rQd4LUg43jsLZSTnzirbTPdIDEVWNeXYfVhY9lZIxKRHNWVYHUhI7jsLe2+MkaAAAARUlEQVQI12OIORpzVBAMGKJ3zt6aBgZg5qtVQLCOIfbq3avlYMAwc+bOmQwQANLWAQYMSEAJDhgeG8MAwxcXKHBjEIQDADRVI7/3XjC6AAAAAElFTkSuQmCC");
}

.language-picker-flag--gb:before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAMAAACDi47UAAAATlBMVEUBIWn88/XQd4z0ztRbbZyrtc2qtc3IEC7AyNrYVWrZ3ehcZpbyxs321drGboVJVInFzN3U2eb8/P3y3OLy9PjT1+REXJFAWI7WTmTzytGKk7Q7AAAAaUlEQVQI123OSQ6AIAwF0ALVikyK8/0valviwoS/IX3kpwXvZgTOQDTKi/Z+oOTGDYP1cbn4T3hFQaWTaeIcWzKVyCSzHzIDddJHre/cJKomba0OcC7O2yCLcI0+F6ZL6TsJZ2Wm//HML3AEBd1F6JYKAAAAAElFTkSuQmCC");
}

.language-picker-flag--ie:before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAAMElEQVQ4jWMUm530nwEL8FU0YZjjnIlNioFhQx0Dw82DWKWYsOsgH4waOGrgYDAQALFFBtgrCSSoAAAAAElFTkSuQmCC");
}

.language-picker-flag--ca:before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKBAMAAABGe2PVAAAAJ1BMVEX/////AAD/mJj/tLT/9vb/FRX/qKj/n5//jIz/2dn/2tr/h4f/jY0Hg2Z8AAAAP0lEQVQI12MQFBRgAAJGQUEGENMtBc5UDYIxjRKFlaHMjYGiElBmo6CgDJTJLChoANOmXgQ3gXMBnMniAGICAOwjCQYGhrBYAAAAAElFTkSuQmCC");
}

.language-picker-flag--au:before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAMAAACDi47UAAAAQlBMVEUBIWnndY1UapoaN3jEscXOfJfdbYlBWY/kACsIKG7f4+wUMnSFd5/ZnbHTw9NbSn5wgqvanbHSxNNsf6gkQH6os8zjmGKXAAAAVUlEQVQIHXXBWRJAMBRFwROSXA8x2/9WRQk+lG6CFzEyBs9L/bw4l4ZJZBKn5kFmxknTMjs3pF5gwLYCPozEiHyAncz4qGsKiY+ubTsuZtwkiqrizwFLpAJdjEggFQAAAABJRU5ErkJggg==");
}

.language-picker-flag--it:before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANBAMAAABbflNtAAAAD1BMVEUAkkZVtoPOKzfecHj///+OE5vxAAAAFklEQVQI12NgYGAQcXFxVlJSYhgETACHHRCCY3/exgAAAABJRU5ErkJggg==");
}

.language-picker-flag--de:before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAKElEQVQ4jWNkYGD4z0BFwERNw4aGgSx3qWzg4Pcy1Q1k/H9uNB1SCACsCwTBHUPRZgAAAABJRU5ErkJggg==");
}

.language-picker-flag--at:before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANAgMAAADUPqbNAAAACVBMVEXIEC7tsLr///+rKbPeAAAAF0lEQVQI12NgwAFCQYBhFQigkBBxHAAABmQNSedW22cAAAAASUVORK5CYII=");
}

.language-picker-flag--ch:before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAAFVBMVEX/AAD/////4OD/QED/wMD/NTX/+Phnzc3eAAAAKklEQVQI12NgIAYEm8KZikJEMlldXBLFXFwCgExmQTAwwMkkxVxU5+ADAMleBlA6opl6AAAAAElFTkSuQmCC");
}

.language-picker-flag--nl:before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANBAMAAABbflNtAAAAFVBMVEUhRouuHCi1wdi2wtnks7fktLj///8hQ1xoAAAAIElEQVQI12MQhAMGgswQFyhwZUiDAxxMZWMoMGIgAQAA9s0TQ2DBwTkAAAAASUVORK5CYII=");
}

.language-picker-flag--es:before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAMAAACejr5sAAAAQlBMVEXGCx7boTvZmhvgrU/SeAi7bE/0sgL/xADxlgf5vgPLsTvMsjq7Vzf5uwDspgDYqLvThhyGSk/AZgrEfSzDgEO/aCXE2PJ7AAAAO0lEQVQY02NgoAxwYAEM7CDAycTGjgTAgnwsLAK86IKMQiL8TJzogqKCrMxsaILcwqw8XMgqsdpOGQAAQywEQ4oRR9oAAAAASUVORK5CYII=");
}

.header {
  position: relative;
}

.header .appbar {
  background-color: #fff;
  width: 100%;
  margin-bottom: 0;
  transition: width .195s cubic-bezier(.4, 0, .6, 1), margin .195s cubic-bezier(.4, 0, .6, 1);
  display: flex;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .08);
}

.header .toolbar {
  border-bottom: 1px solid #f5f5f5;
  width: 85%;
  height: 62px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

@media only screen and (max-width: 1200px) {
  .header .toolbar {
    width: 100%;
  }
}

.header .toolbar-transparent {
  z-index: 100;
  border: 0;
  width: 100%;
  padding-left: 64px;
  padding-right: 64px;
  position: absolute;
  left: 0;
}

@media only screen and (max-width: 1200px) {
  .header .toolbar-transparent {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.header .grow {
  flex-grow: 1;
}

.header .header-title {
  font-size: 32px;
  display: block;
}

@media only screen and (max-width: 1200px) {
  .header .header-title {
    display: none;
  }
}

.header .section-common {
  display: flex;
  position: relative;
}

.header .section-common a, .header .section-common button {
  margin-left: 8px;
  margin-right: 8px;
  padding: 6px;
}

.header .section-desktop {
  display: flex;
  position: relative;
}

@media only screen and (max-width: 1200px) {
  .header .section-desktop {
    display: none;
  }
}

.header .header-button-user {
  margin: 0 12px;
  display: block;
}

.header .header-option-button {
  display: block;
}

.header .header-button-transparent {
  color: #fff;
}

.header .header-admin-dot {
  position: absolute;
  top: 2.5px;
  left: 2.5px;
}

.header .link-button {
  border: 1px solid #ddd;
  border-radius: 18px;
  margin-left: 8px !important;
  padding: 6.4px !important;
}

.header .link-option-home-transparent {
  color: #fff;
}

.header .link-button-icon {
  color: rgba(0, 0, 0, .66);
  fill: currentColor;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 1.2rem;
}

@media only screen and (max-width: 900px) {
  .header .link-button-icon {
    background: none;
    margin-left: auto;
    margin-right: auto;
  }
}

.header .link-button-icon.link-button-icon-light {
  color: rgba(255, 255, 255, .8);
}

.header .desktop-item-button {
  text-transform: initial;
  color: rgba(0, 0, 0, .87);
  white-space: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 5px 16px;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  position: relative;
}

.header .desktop-item-button:before {
  content: "";
  background-color: #7a1802;
  border-radius: 1px;
  width: 18px;
  height: 2px;
  margin-left: -9px;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.header .desktop-item {
  margin-left: 80px;
  margin-right: 32px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 1rem;
}

.header .desktop-item-transparent {
  color: #fff;
  text-shadow: 0 1px 3px #454545;
  background-color: rgba(255, 255, 255, .15);
  border-radius: 12px;
}

.header .desktop-item-focus {
  color: #fff;
  cursor: pointer;
  background-color: #058b5b;
  border: 1px solid #fff;
  border-radius: 16px;
  margin-left: 16px;
  margin-right: 16px;
  font-size: .9rem;
  font-weight: 600;
}

@media only screen and (max-width: 900px) {
  .header .desktop-item-focus {
    display: none;
  }
}

.header .desktop-item-focus:before {
  background-color: inherit;
}

.header .desktop-item-focus.mobile-item {
  display: block;
}

.header .mobile-item-icon {
  color: rgba(0, 0, 0, .87);
  text-transform: initial;
  white-space: nowrap;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
}

@media only screen and (min-width: 901px) {
  .header .mobile-item-icon {
    display: none;
  }
}

.header .mobile-item-icon .mobile-item-icon-img {
  width: 30px;
  height: 30px;
}

.header .desktop-item-badge {
  border: 1px solid #fff;
  min-width: 18px;
  height: 18px;
  font-size: .85rem;
  top: 4px;
  right: 12px;
}

@media only screen and (max-width: 900px) {
  .header .section-product {
    flex-grow: 1;
  }
}

.header .section-mobile {
  display: none;
}

@media only screen and (max-width: 900px) {
  .header .section-mobile {
    display: flex;
  }
}

.header .menu-button-icon {
  border: 1px solid #7a1802;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  padding: 5px;
}

.header .menu-button-icon-transparent {
  color: #fff;
}

.header .mobile-drawer-paper {
  width: 280px;
  position: relative;
}

.header-dropdown {
  top: -4px;
  left: -8px;
}

.header-dropdown .header-dropdown-paper {
  overflow: auto;
}

.header-dropdown .header-dropdown-list {
  padding: 0;
}

.header-dropdown .header-dropdown-item {
  padding-top: 6px;
  padding-bottom: 6px;
}

.header-dropdown .link-title {
  color: rgba(0, 0, 0, .87);
  vertical-align: middle;
  font-size: .9rem;
  font-style: italic;
  line-height: 1rem;
}

.header-dropdown .link-icon {
  min-width: 40px;
}

.header-dropdown .link {
  color: rgba(0, 0, 0, .87);
  vertical-align: middle;
  font-size: 1rem;
  line-height: 1.2rem;
}

.header-dropdown .link-unit, .header-dropdown .link-language {
  font-size: .85rem;
  font-weight: 400 !important;
}

.header-dropdown .link-unit.link-current, .header-dropdown .link-language.link-current {
  font-weight: 600 !important;
}

.header-dropdown .info {
  color: rgba(0, 0, 0, .87);
  vertical-align: middle;
  font-size: .9rem;
  line-height: 1.1rem;
}

.mobile-toolbar {
  margin-top: 10px;
  padding-left: 12px;
  min-height: 48px !important;
}

@media (min-width: 0) and (orientation: landscape) {
  .mobile-toolbar {
    min-height: 48px;
  }
}

@media (min-width: 600px) {
  .mobile-toolbar {
    min-height: 64px;
  }
}

.mobile-toolbar .mobile-title {
  color: rgba(0, 0, 0, .87);
  margin: 5px 2px;
  font-size: 30px;
  display: block;
}

.mobile-menu-icon {
  height: 30px;
}

.mobile-nested-menu {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 24px;
}

.mobile-menu-title {
  text-transform: uppercase;
  padding-right: 8px;
  font-weight: 600;
}

.header-title-minimal {
  display: block !important;
}

.header-brand-logo {
  text-indent: -9999em;
  background: url("@/images/logos/favicon-96x96.png") 0 0 / contain no-repeat;
  width: 80px;
  height: 40px;
  transition: color .6s ease-in-out;
  display: block;
}

@media only screen and (max-width: 900px) {
  .header-brand-logo {
    width: 40px;
  }
}

.header-brand-logo-mobile {
  color: #c82b08;
  background: url("@/images/logos/favicon-96x96.png") 0 0 / contain no-repeat;
  width: 100%;
  height: 38px;
  padding-top: 4px;
  padding-left: 52px;
  font-size: 1.2rem;
  transition: color .6s ease-in-out;
  display: block;
}

.header-brand-logo-minimal {
  color: #c82b08;
  width: auto;
  text-indent: inherit;
  align-items: center;
  padding-left: 50px;
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
}

.main-content {
  background-color: #fff;
  min-height: 85vh;
  margin-left: 0;
  margin-right: 0;
}

.main-layout {
  width: 1320px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1368px) {
  .main-layout {
    width: auto;
    margin-left: 6px;
    margin-right: 6px;
  }
}

.main-section {
  max-width: 70vw;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1536px) {
  .main-section {
    max-width: 85vw;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .main-section {
    max-width: 90vw;
  }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
  .main-section {
    max-width: 96%;
  }
}

@media only screen and (max-width: 600px) {
  .main-section {
    width: auto;
    max-width: 92%;
    margin-top: 2rem;
    margin-left: auto;
  }
}

.footer {
  box-shadow: none;
  text-align: center;
  border-top: 1px solid #ddd;
  width: 70vw;
  margin: 60px auto 0;
  padding-top: 2rem;
}

@media only screen and (max-width: 900px) {
  .footer {
    width: 100%;
    max-width: 92%;
    margin-left: .75rem;
    margin-right: .75rem;
  }
}

.footer .footer-subtitle {
  color: #7a1802;
  margin-top: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .footer .footer-subtitle {
    margin-bottom: .6rem;
    font-size: 1.4rem;
  }
}

.footer .footer-sublink {
  color: rgba(0, 0, 0, .87);
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
  display: block;
}

.footer .footer-link {
  color: rgba(0, 0, 0, .87);
  padding: .3rem 0;
  font-size: .85rem;
}

@media only screen and (max-width: 600px) {
  .footer .footer-link {
    padding: .6rem 0;
    font-size: 1rem;
    line-height: 1rem;
  }
}

.footer .footer-places {
  text-align: center;
  width: 76%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 901px) {
  .footer .footer-places {
    flex-wrap: wrap;
    display: flex;
  }

  .footer .footer-places .col {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 900px) {
  .footer .footer-places {
    width: auto;
  }
}

.footer .footer-rights {
  color: rgba(0, 0, 0, .87);
  text-align: left;
  border-top: 1px solid #e0e0e0;
  margin-top: 3rem;
  padding-top: 18px;
  padding-bottom: 24px;
  font-size: .9rem;
}

@media only screen and (max-width: 900px) {
  .footer .footer-rights {
    margin-top: 2rem;
  }
}

.countdown-container {
  padding: .5rem;
}

@media only screen and (max-width: 600px) {
  .countdown-container {
    padding: .25rem;
  }
}

.countdown-container.countdown-small .countdown-link {
  font-size: 1rem;
}

.countdown-container.countdown-small .countdown {
  line-height: 1rem;
}

.countdown-container.countdown-small .countdown-type {
  line-height: .75rem;
}

.countdown-container .countdown-link {
  color: #000;
  border: 1px solid #ebebeb;
  border-radius: .25rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
  text-decoration: none;
  display: flex;
}

@media only screen and (max-width: 600px) {
  .countdown-container .countdown-link {
    padding: .25rem;
  }
}

.countdown-container .countdown {
  flex-direction: column;
  align-items: center;
  padding: 0 .75rem;
  line-height: 1.25rem;
  display: flex;
}

@media only screen and (max-width: 600px) {
  .countdown-container .countdown {
    padding: 0 .5rem;
  }
}

.countdown-container .countdown.danger {
  color: #c82b08;
}

.countdown-container .countdown .countdown-value {
  margin: 0;
}

.countdown-container .countdown .countdown-type {
  text-transform: uppercase;
  font-size: .75rem;
  line-height: 1rem;
}

.countdown-container .countdown-separator {
  margin: 0;
}

.trial-banner {
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  z-index: 1200;
  background-color: rgba(255, 255, 255, .55);
  width: 100%;
  min-height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, .4);
}

.trial-banner.scrolled-down {
  transition: transform .3s ease-in-out;
  transform: translateY(100%);
}

.trial-banner.scrolled-up {
  transition: transform .3s ease-in-out;
  transform: translateY(0);
}

.trial-banner-container {
  color: #000;
  width: 84%;
  max-width: calc(1344px + 6rem);
  margin: 1rem auto;
  padding-left: 3rem;
  padding-right: 3rem;
  position: relative;
}

@media only screen and (max-width: 900px) {
  .trial-banner-container {
    width: 90%;
    padding-left: 0;
    padding-right: 0;
  }
}

.trial-banner-container .countdown-link {
  border: 1px solid rgba(255, 255, 255, .8);
}

.trial-banner-container .countdown-value {
  color: #c82b08;
}

.hover\:text-no-shadow:hover {
  text-shadow: none;
}

@media (min-width: 900px) {
  .md\:max-w-initial {
    max-width: initial;
  }

  .md\:divide-x-1 > * + * {
    border-left-width: 1px;
    border-right-width: 0;
  }
}

.before\:h-\[18px\]:before {
  content: var(--tw-content);
  height: 18px;
}

.before\:w-\[18px\]:before {
  content: var(--tw-content);
  width: 18px;
}

.before\:rounded-full:before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:start-\[0px\]:after {
  content: var(--tw-content);
}

.after\:start-\[0px\]:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))):after {
  left: 0;
}

.after\:start-\[0px\]:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))):after {
  left: 0;
}

.after\:start-\[0px\]:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):after {
  right: 0;
}

.after\:start-\[0px\]:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):after {
  right: 0;
}

.after\:start-\[2px\]:after {
  content: var(--tw-content);
}

.after\:start-\[2px\]:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))):after {
  left: 2px;
}

.after\:start-\[2px\]:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))):after {
  left: 2px;
}

.after\:start-\[2px\]:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):after {
  right: 2px;
}

.after\:start-\[2px\]:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):after {
  right: 2px;
}

.after\:top-\[0px\]:after {
  content: var(--tw-content);
  top: 0;
}

.after\:top-\[2px\]:after {
  content: var(--tw-content);
  top: 2px;
}

.after\:h-3:after {
  content: var(--tw-content);
  height: .75rem;
}

.after\:h-5:after {
  content: var(--tw-content);
  height: 1.25rem;
}

.after\:w-3:after {
  content: var(--tw-content);
  width: .75rem;
}

.after\:w-5:after {
  content: var(--tw-content);
  width: 1.25rem;
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:border:after {
  content: var(--tw-content);
  border-width: 1px;
}

.after\:border-gray-300:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity, 1));
}

.after\:bg-white:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity, 1));
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.after\:content-\[\\\'\\\'\]:after {
  --tw-content: \'\';
  content: var(--tw-content);
}

.first\:mt-1:first-child {
  margin-top: .25rem;
}

.focus-within\:outline-none:focus-within {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.focus-within\:ring-4:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.focus-within\:ring-primary-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 213, 204, var(--tw-ring-opacity, 1));
}

.hover\:border-2:hover {
  border-width: 2px;
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity, 1));
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 86, 219, var(--tw-bg-opacity, 1));
}

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 66, 159, var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity, 1));
}

.hover\:bg-green-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 84, 63, var(--tw-bg-opacity, 1));
}

.hover\:bg-orange-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(138, 44, 13, var(--tw-bg-opacity, 1));
}

.hover\:bg-primary-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 241, 238, var(--tw-bg-opacity, 1));
}

.hover\:bg-primary-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 213, 204, var(--tw-bg-opacity, 1));
}

.hover\:bg-primary-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 188, 173, var(--tw-bg-opacity, 1));
}

.hover\:bg-primary-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 121, 93, var(--tw-bg-opacity, 1));
}

.hover\:bg-primary-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 86, 47, var(--tw-bg-opacity, 1));
}

.hover\:bg-primary-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(235, 79, 39, var(--tw-bg-opacity, 1));
}

.hover\:bg-primary-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 69, 34, var(--tw-bg-opacity, 1));
}

.hover\:bg-purple-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 33, 181, var(--tw-bg-opacity, 1));
}

.hover\:bg-red-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(155, 28, 28, var(--tw-bg-opacity, 1));
}

.hover\:bg-secondary-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 172, 116, var(--tw-bg-opacity, 1));
}

.hover\:bg-teal-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(175, 236, 239, var(--tw-bg-opacity, 1));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity, 1));
}

.hover\:bg-yellow-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(194, 120, 3, var(--tw-bg-opacity, 1));
}

.hover\:bg-yellow-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 49, 18, var(--tw-bg-opacity, 1));
}

.hover\:bg-none:hover {
  background-image: none;
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(28, 100, 242, var(--tw-text-opacity, 1));
}

.hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgba(26, 86, 219, var(--tw-text-opacity, 1));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity, 1));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity, 1));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity, 1));
}

.hover\:text-primary-800:hover {
  --tw-text-opacity: 1;
  color: rgba(204, 69, 34, var(--tw-text-opacity, 1));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity, 1));
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.hover\:outline:hover {
  outline-style: solid;
}

.hover\:outline-primary-400\/30:hover {
  outline-color: rgba(255, 188, 173, .3);
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(63, 131, 248, var(--tw-border-opacity, 1));
}

.focus\:border-primary-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 121, 93, var(--tw-border-opacity, 1));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.focus\:ring-black:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity, 1));
}

.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(164, 202, 254, var(--tw-ring-opacity, 1));
}

.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 131, 248, var(--tw-ring-opacity, 1));
}

.focus\:ring-gray-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity, 1));
}

.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity, 1));
}

.focus\:ring-gray-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity, 1));
}

.focus\:ring-green-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(132, 225, 188, var(--tw-ring-opacity, 1));
}

.focus\:ring-green-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(14, 159, 110, var(--tw-ring-opacity, 1));
}

.focus\:ring-orange-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 186, 140, var(--tw-ring-opacity, 1));
}

.focus\:ring-orange-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 90, 31, var(--tw-ring-opacity, 1));
}

.focus\:ring-primary-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 213, 204, var(--tw-ring-opacity, 1));
}

.focus\:ring-primary-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 121, 93, var(--tw-ring-opacity, 1));
}

.focus\:ring-purple-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(202, 191, 253, var(--tw-ring-opacity, 1));
}

.focus\:ring-purple-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(144, 97, 249, var(--tw-ring-opacity, 1));
}

.focus\:ring-red-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 180, 180, var(--tw-ring-opacity, 1));
}

.focus\:ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 82, 82, var(--tw-ring-opacity, 1));
}

.focus\:ring-secondary-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 197, 157, var(--tw-ring-opacity, 1));
}

.focus\:ring-secondary-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(20, 172, 116, var(--tw-ring-opacity, 1));
}

.focus\:ring-teal-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(126, 220, 226, var(--tw-ring-opacity, 1));
}

.focus\:ring-teal-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 148, 162, var(--tw-ring-opacity, 1));
}

.focus\:ring-yellow-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 202, 21, var(--tw-ring-opacity, 1));
}

.focus\:ring-yellow-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(194, 120, 3, var(--tw-ring-opacity, 1));
}

.active\:bg-primary-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 86, 47, var(--tw-bg-opacity, 1));
}

.active\:text-white:active {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity, 1));
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:bg-primary-500:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 121, 93, var(--tw-bg-opacity, 1));
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.group:hover .group-hover\:-translate-y-3 {
  --tw-translate-y: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity, 1));
}

.group:hover .group-hover\:shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, .25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.group:focus .group-focus\:outline {
  outline-style: solid;
}

.peer:checked ~ .peer-checked\:border-primary-700 {
  --tw-border-opacity: 1;
  border-color: rgba(235, 79, 39, var(--tw-border-opacity, 1));
}

.peer:checked ~ .peer-checked\:bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(28, 100, 242, var(--tw-bg-opacity, 1));
}

.peer:checked ~ .peer-checked\:bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 122, 85, var(--tw-bg-opacity, 1));
}

.peer:checked ~ .peer-checked\:bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(208, 56, 1, var(--tw-bg-opacity, 1));
}

.peer:checked ~ .peer-checked\:bg-primary-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 86, 47, var(--tw-bg-opacity, 1));
}

.peer:checked ~ .peer-checked\:bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(126, 58, 242, var(--tw-bg-opacity, 1));
}

.peer:checked ~ .peer-checked\:bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 36, 36, var(--tw-bg-opacity, 1));
}

.peer:checked ~ .peer-checked\:bg-secondary-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(13, 157, 105, var(--tw-bg-opacity, 1));
}

.peer:checked ~ .peer-checked\:bg-teal-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 116, 129, var(--tw-bg-opacity, 1));
}

.peer:checked ~ .peer-checked\:bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(159, 88, 10, var(--tw-bg-opacity, 1));
}

.peer:checked ~ .peer-checked\:text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity, 1));
}

.peer:checked ~ .peer-checked\:after\:start-\[4px\]:after {
  content: var(--tw-content);
}

.peer:checked ~ .peer-checked\:after\:start-\[4px\]:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))):after {
  left: 4px;
}

.peer:checked ~ .peer-checked\:after\:start-\[4px\]:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))):after {
  left: 4px;
}

.peer:checked ~ .peer-checked\:after\:start-\[4px\]:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):after {
  right: 4px;
}

.peer:checked ~ .peer-checked\:after\:start-\[4px\]:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)):after {
  right: 4px;
}

.peer:checked ~ .peer-checked\:after\:top-\[-2px\]:after {
  content: var(--tw-content);
  top: -2px;
}

.peer:checked ~ .peer-checked\:after\:h-4:after {
  content: var(--tw-content);
  height: 1rem;
}

.peer:checked ~ .peer-checked\:after\:w-4:after {
  content: var(--tw-content);
  width: 1rem;
}

.peer:checked ~ .peer-checked\:after\:translate-x-full:after {
  content: var(--tw-content);
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:after\:border-white:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity, 1));
}

.peer:focus ~ .peer-focus\:outline-none {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.peer:focus ~ .peer-focus\:ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.peer:focus ~ .peer-focus\:ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.peer:focus ~ .peer-focus\:ring-blue-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(164, 202, 254, var(--tw-ring-opacity, 1));
}

.peer:focus ~ .peer-focus\:ring-green-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(132, 225, 188, var(--tw-ring-opacity, 1));
}

.peer:focus ~ .peer-focus\:ring-orange-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 186, 140, var(--tw-ring-opacity, 1));
}

.peer:focus ~ .peer-focus\:ring-primary-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 213, 204, var(--tw-ring-opacity, 1));
}

.peer:focus ~ .peer-focus\:ring-primary-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 121, 93, var(--tw-ring-opacity, 1));
}

.peer:focus ~ .peer-focus\:ring-purple-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(202, 191, 253, var(--tw-ring-opacity, 1));
}

.peer:focus ~ .peer-focus\:ring-red-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 180, 180, var(--tw-ring-opacity, 1));
}

.peer:focus ~ .peer-focus\:ring-secondary-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 197, 157, var(--tw-ring-opacity, 1));
}

.peer:focus ~ .peer-focus\:ring-teal-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(126, 220, 226, var(--tw-ring-opacity, 1));
}

.peer:focus ~ .peer-focus\:ring-yellow-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 202, 21, var(--tw-ring-opacity, 1));
}

.dark\:divide-gray-600:is(.dark *) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-divide-opacity, 1));
}

.dark\:border-gray-500:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity, 1));
}

.dark\:border-gray-600:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity, 1));
}

.dark\:border-gray-700:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity, 1));
}

.dark\:border-gray-900:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity, 1));
}

.dark\:border-primary-400:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(255, 188, 173, var(--tw-border-opacity, 1));
}

.dark\:border-primary-500:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(254, 121, 93, var(--tw-border-opacity, 1));
}

.dark\:border-red-500:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(240, 82, 82, var(--tw-border-opacity, 1));
}

.dark\:border-red-800:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(155, 28, 28, var(--tw-border-opacity, 1));
}

.dark\:border-yellow-300:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(250, 202, 21, var(--tw-border-opacity, 1));
}

.dark\:border-yellow-800:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(114, 59, 19, var(--tw-border-opacity, 1));
}

.dark\:bg-blue-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(28, 100, 242, var(--tw-bg-opacity, 1));
}

.dark\:bg-gray-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity, 1));
}

.dark\:bg-gray-700:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity, 1));
}

.dark\:bg-gray-800:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity, 1));
}

.dark\:bg-gray-900:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity, 1));
}

.dark\:bg-gray-900\/80:is(.dark *) {
  background-color: rgba(17, 24, 39, .8);
}

.dark\:bg-green-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 122, 85, var(--tw-bg-opacity, 1));
}

.dark\:bg-orange-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(208, 56, 1, var(--tw-bg-opacity, 1));
}

.dark\:bg-primary-200:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 228, 222, var(--tw-bg-opacity, 1));
}

.dark\:bg-primary-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 86, 47, var(--tw-bg-opacity, 1));
}

.dark\:bg-purple-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(126, 58, 242, var(--tw-bg-opacity, 1));
}

.dark\:bg-red-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 36, 36, var(--tw-bg-opacity, 1));
}

.dark\:bg-red-900:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(119, 29, 29, var(--tw-bg-opacity, 1));
}

.dark\:bg-secondary-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(13, 157, 105, var(--tw-bg-opacity, 1));
}

.dark\:bg-teal-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 116, 129, var(--tw-bg-opacity, 1));
}

.dark\:fill-gray-300:is(.dark *) {
  fill: #d1d5db;
}

.dark\:text-blue-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(118, 169, 250, var(--tw-text-opacity, 1));
}

.dark\:text-gray-200:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity, 1));
}

.dark\:text-gray-300:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity, 1));
}

.dark\:text-gray-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity, 1));
}

.dark\:text-gray-500:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity, 1));
}

.dark\:text-gray-600:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity, 1));
}

.dark\:text-green-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(49, 196, 141, var(--tw-text-opacity, 1));
}

.dark\:text-primary-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(255, 188, 173, var(--tw-text-opacity, 1));
}

.dark\:text-primary-500:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(254, 121, 93, var(--tw-text-opacity, 1));
}

.dark\:text-primary-800:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(204, 69, 34, var(--tw-text-opacity, 1));
}

.dark\:text-red-300:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(248, 180, 180, var(--tw-text-opacity, 1));
}

.dark\:text-red-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(249, 128, 128, var(--tw-text-opacity, 1));
}

.dark\:text-red-500:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(240, 82, 82, var(--tw-text-opacity, 1));
}

.dark\:text-white:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity, 1));
}

.dark\:text-yellow-300:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(250, 202, 21, var(--tw-text-opacity, 1));
}

.dark\:placeholder-gray-400:is(.dark *)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity, 1));
}

.dark\:ring-offset-gray-800:is(.dark *) {
  --tw-ring-offset-color: #1f2937;
}

.dark\:focus-within\:ring-primary-800:focus-within:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(204, 69, 34, var(--tw-ring-opacity, 1));
}

.dark\:hover\:bg-blue-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 86, 219, var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-gray-600:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-gray-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-green-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 108, 78, var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-orange-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 52, 3, var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-primary-500:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 121, 93, var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-primary-600:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 86, 47, var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-primary-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(235, 79, 39, var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-purple-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(108, 43, 217, var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-red-600:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 36, 36, var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-red-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(200, 30, 30, var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-secondary-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 139, 92, var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-teal-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 102, 114, var(--tw-bg-opacity, 1));
}

.dark\:hover\:bg-yellow-300:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 202, 21, var(--tw-bg-opacity, 1));
}

.dark\:hover\:text-gray-300:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity, 1));
}

.dark\:hover\:text-gray-800:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity, 1));
}

.dark\:hover\:text-white:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity, 1));
}

.dark\:focus\:border-blue-500:focus:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(63, 131, 248, var(--tw-border-opacity, 1));
}

.dark\:focus\:border-primary-500:focus:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgba(254, 121, 93, var(--tw-border-opacity, 1));
}

.dark\:focus\:ring-blue-500:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(63, 131, 248, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-blue-600:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(28, 100, 242, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-blue-800:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 66, 159, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-gray-200:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-gray-600:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-gray-700:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-green-600:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 122, 85, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-green-800:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 84, 63, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-orange-600:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(208, 56, 1, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-orange-800:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(138, 44, 13, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-primary-500:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 121, 93, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-primary-600:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 86, 47, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-primary-800:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(204, 69, 34, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-primary-900:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 55, 27, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-purple-600:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(126, 58, 242, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-purple-800:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(85, 33, 181, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-red-600:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 36, 36, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-red-800:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(155, 28, 28, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-red-900:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(119, 29, 29, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-secondary-600:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(13, 157, 105, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-secondary-800:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 122, 80, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-teal-600:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 116, 129, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-teal-800:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 80, 92, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-yellow-600:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(159, 88, 10, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-yellow-800:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(114, 59, 19, var(--tw-ring-opacity, 1));
}

.dark\:focus\:ring-yellow-900:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 49, 18, var(--tw-ring-opacity, 1));
}

.group:hover .dark\:group-hover\:text-white:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity, 1));
}

.peer:focus ~ .dark\:peer-focus\:ring-primary-800:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(204, 69, 34, var(--tw-ring-opacity, 1));
}

@media (min-width: 640px) {
  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:justify-around {
    justify-content: space-around;
  }

  .sm\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }
}

@media (min-width: 900px) {
  .md\:inset-0 {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .md\:right-2\.5 {
    right: .625rem;
  }

  .md\:order-1 {
    order: 1;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md\:-mt-8 {
    margin-top: -2rem;
  }

  .md\:me-0:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
    margin-right: 0;
  }

  .md\:me-0:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
    margin-right: 0;
  }

  .md\:me-0:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    margin-left: 0;
  }

  .md\:me-0:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    margin-left: 0;
  }

  .md\:me-3:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
    margin-right: .75rem;
  }

  .md\:me-3:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
    margin-right: .75rem;
  }

  .md\:me-3:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    margin-left: .75rem;
  }

  .md\:me-3:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    margin-left: .75rem;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:ml-4 {
    margin-left: 1rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:inline-flex {
    display: inline-flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-12 {
    height: 3rem;
  }

  .md\:h-52 {
    height: 13rem;
  }

  .md\:h-\[63vh\] {
    height: 63vh;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:max-h-28 {
    max-height: 7rem;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:w-28 {
    width: 7rem;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:max-w-md {
    max-width: 28rem;
  }

  .md\:max-w-xl {
    max-width: 36rem;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:items-stretch {
    align-items: stretch;
  }

  .md\:gap-12 {
    gap: 3rem;
  }

  .md\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:rounded-none {
    border-radius: 0;
  }

  .md\:rounded-s-lg:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .md\:rounded-s-lg:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .md\:rounded-s-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .md\:rounded-s-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .md\:border {
    border-width: 1px;
  }

  .md\:border-0 {
    border-width: 0;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:p-5 {
    padding: 1.25rem;
  }

  .md\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:ps-10:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
    padding-left: 2.5rem;
  }

  .md\:ps-10:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
    padding-left: 2.5rem;
  }

  .md\:ps-10:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    padding-right: 2.5rem;
  }

  .md\:ps-10:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    padding-right: 2.5rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .md\:outline-2 {
    outline-width: 2px;
  }

  .md\:outline-primary-600 {
    outline-color: #ef562f;
  }

  .md\:hover\:text-primary-800:hover {
    --tw-text-opacity: 1;
    color: rgba(204, 69, 34, var(--tw-text-opacity, 1));
  }

  .md\:dark\:hover\:bg-transparent:hover:is(.dark *) {
    background-color: rgba(0, 0, 0, 0);
  }

  .md\:dark\:hover\:text-blue-500:hover:is(.dark *) {
    --tw-text-opacity: 1;
    color: rgba(63, 131, 248, var(--tw-text-opacity, 1));
  }
}

@media (min-width: 1200px) {
  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:border-0 {
    border-width: 0;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (min-width: 1536px) {
  .xl\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
}

.rtl\:rotate-180:where([dir="rtl"], [dir="rtl"] *) {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rtl\:space-x-reverse:where([dir="rtl"], [dir="rtl"] *) > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}

.rtl\:text-right:where([dir="rtl"], [dir="rtl"] *) {
  text-align: right;
}

.peer:checked ~ .rtl\:peer-checked\:after\:-translate-x-full:where([dir="rtl"], [dir="rtl"] *):after {
  content: var(--tw-content);
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
